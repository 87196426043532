import React from 'react';
import Seo from '../components/layout/Seo'
// import Button from '../components/Button';
// import Banner from '../components/Banner';
import HomepageBanner from '../components/HomepageBanner';
import Layout from '../components/layout/Layout';
import Section from '../components/layout/Section'
// import { StaticImage} from "gatsby-plugin-image"
import SplitBackground from '../svg/SplitBG'
// import SocialmediaLinks from '../components/SocialmediaLinks'
// import LoadingScreen from '../components/LoadingScreen'
// import Fade from 'react-reveal/Fade';

const HomePage = () => {

  // const [toggler, setToggler] = useState(false);

  // const sliderOptions = {

  //   type: 'fade',
  //   rewind: true,
  // }

  // const [loading, setLoading] = useState(true)

  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 2000)
  // }, [])

  return (
    <Layout>
      <Seo title="Home" />

      <Section sectionName="intro" containerSize={`xl`}>
      <div className="bg-transparent block" style={{minHeight: "80vh"}}>

      <HomepageBanner />

	    {/* <Banner heading={`Let the Natural World Speak`} subtitle={`Debut LP Höhn Out Now`}
              caption={`Ambient and lucid, an ethereal trance, you plug in, unclench, submit to becoming unknown in the wild and return to your instincts.`}
	            btnText="find a stream"
	            btnIsLink={true}
	            btnUrl="/music#hohn"
              location="homepage"
              className={`text-center`}
	            >
        <Fade duration={400}>
          <StaticImage
              src="../images/music/hohn_album_cover_art_web.jpg"
              quality={75}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="hohn"
              className="shadow-xl"
            />
        </Fade>
	    </Banner> */}
      </div>
      </Section>

      <SplitBackground />

    {/*
      loading === false ? (
        ''
      ) : (
        <LoadingScreen />
      )
    */}

    </Layout>
  )
}

export default HomePage;
